import {_delete, _post, _put} from "@api/services/httpService";

export const CLUB_PHOTO = 'clubs/photos'
export const CLUB_LOGO = 'clubs/logos'

export const putClubPhoto = (id, photo) => _put(CLUB_PHOTO + '/' + id, photo);

export const postClubPhoto = (formData) => _post(CLUB_PHOTO,
    formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
;

export const putClubLogo = (id, photo) => _put(CLUB_LOGO + '/' + id, photo);

export const postClubLogo = (formData) => _post(CLUB_LOGO,
    formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
;

export const deleteClubPhoto = (id) => _delete(CLUB_PHOTO + '/' + id);
