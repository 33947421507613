import {_post} from "@api/services/httpService";
import {ACTIVITIES_PHOTO_URL} from "@api/services/activities/activities.api";
import {IMPORT_CSV_URL} from "@api/services/client-import-file/client-import-file.api";
import {CLUB_LOGO, CLUB_PHOTO} from "@api/services/club/club-photo.api";


const headers = { "Content-Type": "multipart/form-data" };

export const getUrlFromTarget = (payload, formData) => {
  const target = payload.target;

  switch (target) {
    case 'postActivityPhoto':
      return ACTIVITIES_PHOTO_URL;
    case 'postClubLogo':
    case 'putClubLogo':
      return CLUB_LOGO;
    case 'postClubPhoto':
    case 'putClubPhoto':
      return CLUB_PHOTO;
    case 'postCsvFile':
      return IMPORT_CSV_URL;
  }
}

export const postPhoto = (URL, formData) => _post(URL,
  formData, {
    headers: headers
  })
;
