import Vue from 'vue';
import {getUrlFromTarget, postPhoto} from "@api/services/uploaderService";

  const fileManagerPlugin = {
    filePath(token) {
      if (!token) {
        return null;
      }

      const uri = process.env.VUE_APP_UPLOAD_FILE_PATH;

      if (uri) {
        return uri.replace('{token}', token);
      } else {
        return '';
    }
  },

  upload(callback, payload) {
    if (payload === undefined) {
      payload = {};
    }

    const onFileBrowserClose = (file) => {
      document.body.onfocus = null;
      document.body.removeChild(input);
    };

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('id', 'file-upload-id');
    input.setAttribute('style', 'display: none;');
    input.addEventListener('change', (e) => {
      if (e.target.files.length) {
        if (payload.url === null) {
          const file = e.target.files[0];
          callback(file);
        } else {
          this.$uploadFile(e.target.files[0], payload)
            .then(uploadFile => callback(uploadFile))
          ;
        }
      }
    });
    input.addEventListener('click', (e) => {
      document.body.onfocus = onFileBrowserClose;
    });

    if (payload.accept) {
      input.setAttribute('accept', payload.accept);
    }

    document.body.appendChild(input);
    input.click();
  },

    uploadFile(file, payload) {
      payload.file = file;
      let formData = new FormData();

      formData.append("file", file);
      if (null !== payload.url) {
        formData.append(payload.entry, payload.url);
      }

      const fileUploadURl = getUrlFromTarget(payload, formData);

      return postPhoto(fileUploadURl, formData)
        .then(response => response.data)
        .catch(error => console.error('file upload failed', error))
        ;
    },

    install(Vue, options) {
    Vue.prototype.$uploadFile = this.uploadFile;
    Vue.prototype.$upload = this.upload;
    Vue.prototype.$filePath = this.filePath;
    Vue.prototype.$download = this.download;
  }
};

Vue.use(fileManagerPlugin);

export default fileManagerPlugin;
