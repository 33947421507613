import { _post } from "@api/services/httpService";

export const IMPORT_CSV_URL = 'clubs/client-imports/files'

export const postImportClientFile = (data) => {
  return _post(IMPORT_CSV_URL, data,{
    headers: {
      "Content-Type": "multipart/form-data"
    }}
  );
}
